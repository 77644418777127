<template>
  <div>
    <v-dialog
      v-model="openDialog"
      class="dialogAddPayment"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="headline info white--text">
          Cambiar contraseña
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <loading :active.sync="myLoading.isLoading" :is-full-page="myLoading.fullPage"></loading>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Ingrese la contraseña"
                  prepend-icon="mdi-lock"
                  color="primary"
                  v-model="password"
                  required
                  :rules="[rules.required, rules.min]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Confirme la contraseña"
                  prepend-icon="mdi-lock-check"
                  color="primary"
                  v-model="passwordConfirm"
                  required
                  :rules="[rules.required, rules.min, rules.equal]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!validForm"
            @click="validateForm"
          >
            <v-icon>mdi-conten-save</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import firebase from 'firebase/app';
  import 'firebase/auth';
  const axios = require('axios');
  export default {
    name: 'UserResetPassword',
    props:{
      uid: {
        type: String,
        required: true,
      },
      open: {
        type: Boolean,
        required: true,
      }
    },
    data() {
      return {
        openDialog:this.open,
        validForm:true,
        myLoading:{
          isLoading:false,
          fullPage:false
        },
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        passwordConfirm: '',
        password: '',
        rules: {
          required: (v) => (v && v.length > 0) || "Campo requerido",
          min: (v) => v.length >= 8 || "Min 8 caracteres",
          equal:(v) => {
            if (this.password !== v) {
              return 'Las contraseñas no coinciden'
            }
            return true
          }
        },
      }
    },
    methods: {
      closeDialog() {
        this.openDialog = false;
        this.$emit('update:open', false)
      },
      async validateForm() {
        if (this.$refs.form.validate()) {
          this.myLoading.isLoading = true
          firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            var config = {
              method: 'post',
              url: process.env.VUE_APP_API_URL+'/api/users/update',
              data: {uid:this.uid, password: this.password},
              headers:{
                'token':idToken
              }
            };
            axios(config)
            .then(async () => {
              this.snackbar.show = true;
              this.snackbar.color = 'green';
              this.snackbar.message = `La contraseña se actualizo correctamente`;
              this.myLoading.isLoading = false
            })
            .catch((error) => {
              console.log(error);
              this.myLoading.isLoading = false
              if (error.response && error.response.data && error.response.data.error) {
                this.showSnackbarError(error.response.data.error.message)
              } else {
                this.showSnackbarError('La contraseña no se ha podido actualizar, intente de nuevo. Si el problema persiste contacte al Administrador')
              }
            });
          }).catch((error) => {
            // Handle error
            console.log('error al obtener el token', error);
            this.myLoading.isLoading = false
            this.showSnackbarError('La contraseña no se ha podido actualizar, intente de nuevo. Si el problema persiste contacte al Administrador')
          });
        }
      },
    },
    watch:{
      open(newValue) {
        this.openDialog = newValue;
      }
    }
  }
</script>