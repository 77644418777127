<template>
  <div>
    <portal to="title-page">
      <h1>Editar usuario</h1>
    </portal>
    <Loading :active="loading"/>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          {{mdiPlus}}
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small class="ml-auto" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          v-if="!loading"
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="item.email"
              :rules="emailRules"
              prepend-icon="mdi-email"
              required
              dense
              disabled
              label="Email*" ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="item.password"
              :rules="nameRules"
              prepend-icon="mdi-lock"
              required
              dense
              label="Password*" hint="Ingrese el password del usuario" persistent-hint></v-text-field>
            </v-col> -->
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="item.name"
              :rules="nameRules"
              prepend-icon="mdi-account"
              required
              dense
              label="Nombre*" ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="item.lastname"
              :rules="nameRules"
              prepend-icon="mdi-text"
              required
              dense
              label="Apellidos*" ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="">
              <v-select
                v-model="item.role"
                :items="roles"
                menu-props="auto"
                label="Role"
                hide-details
                prepend-icon="mdi-cog"
                dense
              ></v-select>
            </v-col>
            <!-- <v-col cols="12" md="12" class="">
              <v-switch
                v-model="item.status"
                inset
                :label="`Status: ${item.status ? 'active' : 'inactive'}`"
              ></v-switch>
            </v-col> -->
            
            <v-col v-if="item.role == 'diver'" cols="12" md="12" class="my-4">
              <h3 class="mt-2">Datos del buzo</h3>
              <div class="d-flex align-center">
                <v-checkbox
                  v-model="item.hasDived"
                  label="Ha buceado"
                ></v-checkbox>
                <a :href="certificationFile" class="ml-4" target="_blank">Ver documentación</a>
              </div>
              <!-- <p v-if="!item.hasDived" class="danger--text">No ha buceado</p> -->
              <!-- <p v-if="item.hasDived" class="success--text">Ha buceado</p> -->
              <div v-if="item.businessType == 'business'">
                <v-text-field
                  v-model="item.business"
                  :rules="nameRules"
                  prepend-icon="mdi-domain"
                  required
                  dense
                  label="Empresa*" ></v-text-field>
              </div>
              <div v-if="item.businessType == 'freelance'" >
                <v-text-field
                  value="Freelance"
                  prepend-icon="mdi-domain"
                  dense
                  label="Empresa*" ></v-text-field>
              </div>
              <div cols="12" sm="12" md="12">
                <MazPhoneNumberInput
                  :default-country-code="item.phone.countryCode"
                    v-model="phone"
                    @update="updateCountry"
                    noExample
                    placeholder="Telefono"
                    hint="Telefono"
                  />
              </div>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="12" class="">
              <v-btn
                :disabled="!validForm"
                color="primary"
                class="mr-4"
                @click="validateForm"
              >
                <v-icon>mdi-content-save</v-icon>
                Guardar
              </v-btn>
              <v-btn
                color="red"
                class="mr-4 white--text"
                @click="modalPassword = true"
              >
                <v-icon>mdi-lock-alert</v-icon>
                Cambiar contraseña
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <ResetPassword v-if="!loading" :open.sync="modalPassword" :uid="item.uid"></ResetPassword>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template> 
<script>
  import Vue from "vue";
  import { mdiPlus, mdiArrowLeft, mdiFlag, mdiText, mdiClose } from '@mdi/js';
  import Loading from "@/components/Loading";
  import ResetPassword from "@/components/users/ResetPassword";
  import firebase from 'firebase/app';
  import 'firebase/storage';

  // import firebase from 'firebase/app';
  // import 'firebase/auth';
  // const axios = require('axios');

  import User from "@/models/User.js";
  import { MazPhoneNumberInput } from 'maz-ui'

  Vue.use(MazPhoneNumberInput)
  Vue.use('Loading', Loading);
  export default {
    name: "UserEdit",
    components: { ResetPassword },
    data() {
      return {
        ///////////////////////////////////////////////////////
        mdiPlus:mdiPlus,
        mdiArrowLeft:mdiArrowLeft,
        mdiFlag:mdiFlag,
        mdiText:mdiText,
        mdiClose:mdiClose,
        certificationFile:'',
        phone:true,
        countryData:null,
        validForm:true,
        modalPassword:false,
        customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["code-block"],
        ],
        nameRules: [
          v => !!v || 'El nombre es obligatorio',
        ],
        emailRules: [
          v => !!v || 'E-mail es requerido',
          v => /.+@.+\..+/.test(v) || 'E-mail debe ser valido',
        ],
        /*item:{
          email: '',
          name: '',
          lastname: '',
          role: 'taquilla',
          status: false
        },*/
        loading: true,
        roles:['admin', 'taquilla', 'diver'],
        descriptionRules: [
          v => !!v || 'La descripcion es obligatoria',
          v => (v && v.length >= 10) || 'La descripcion debe ser de al menos 10 caracteres',
        ],
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        model: new User
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    },
    methods:{
      validateForm() {
        if (this.$refs.form.validate()) {
          let loading = this.$loading.show();
          const item = {...this.item}
          item.phone = this.countryData
          delete item.phone.type
          this.model.update(this.item.id, item).then(() => {
            loading.hide()
            this.showSnackbarSuccess('El usuario se ha actualizado correctamente')
          })
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      updateCountry(e) {
        this.countryData = e
      },
    },
    mounted() {
      this.model.findById(this.$route.params.id).onSnapshot(doc => {
        this.loading = false
        if (doc.exists) {
          this.item = { id:doc.id, ... doc.data() }
          this.phone = this.item.phone.formatNational
          if (this.item.role == 'diver') {
            var storage = firebase.storage();
            storage
              .ref('users/')
              .child(this.item.certificationFile)
              .getDownloadURL()
              .then((url) => {
                this.certificationFile = url;
                //Firebase Storage: The operation 'getDownloadURL' cannot be performed on a root reference, create a non-root reference using child, such as .child('file.png').
            }).catch(function(error) {
              console.log('error download url', error)
            });
          }
        }
      })
    },
  }
</script>
<style>
  #app .quillWrapper.error {
    background-color: transparent !important;
  }
  .quillWrapper.error > div {
    border-color: #ff5252;
    background-color: transparent;
  }
  .maz-phone-number-input .country-selector .maz-input{
    border-width: 1px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-left-radius: 0;
    border-color: rgba(0, 0, 0, 0.87);
  }
  .maz-phone-number-input .country-selector {
    flex: 0 0 7.857rem;
    width: 7.857rem;
    min-width: 7.857rem;
    max-width: 7.857rem;
  }
  .maz-phone-number-input .maz-input.input-phone-number:not(.has-border-radius){
    border-width: 1px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-right-radius: 0;
    border-color: rgba(0, 0, 0, 0.87);
  }
</style>